import React from 'react'
import { Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'
import { formatEntry } from 'src/components/utils'
import { useMdxEntries } from 'src/hooks/use-mdx-entries'

function withinNyears(year, maxYears) {
  if (maxYears === -1) {
    return true
  } else {
    return ((new Date().getFullYear() - parseInt(year)) <= maxYears )
  }
}

function news(entries, maxYears) {
  // find relevant entries
  let remaining = entries.filter(({ node }) => {
    const { year, title, tags } = node.frontmatter
    return (title.length > 0 && tags.includes("news") && !tags.includes("hiring") && withinNyears(year, maxYears))
  })
  // conditional rendering
  if (remaining.length === 0) {
    return (
      <div><p><i>No news to share at this time.</i></p></div>
    )
  } else {
    return (
      <div>
        {remaining.map(({ node }) => formatEntry(node))}
      </div> 
    )
  }
}

function RawNewsPage({ maxYears }) {
  const { edges: entries } = useMdxEntries()
  return (
    <>
      {/* <h1>News</h1> */}
      {news(entries, maxYears)}
    </>
  )
}

function NewsPage({ maxYears }) {
  return (
    <BasicLayout>
      <RawNewsPage maxYears={maxYears}/>
    </BasicLayout>
  )
}

export {
  withinNyears,
  news,
  RawNewsPage,
  NewsPage,
}
