import React from 'react'
import { Link } from 'gatsby'
import { formatEntry } from 'src/components/utils'
import BasicLayout from 'src/components/BasicLayout'
import { useMdxEntries } from 'src/hooks/use-mdx-entries'


function activelyHiring(entries) {
  // find relevant entries
  let remaining = entries.filter(({ node }) => {
    const { date, title, section, tags } = node.frontmatter
    
    return (title.length > 0 && tags.includes("hiring") && !tags.includes("expired"))
  })
  // conditional rendering
  if (remaining.length == 0) {
    return (
      <div><p><i>No active positions at this time.</i></p></div>
    )
  } else {
    return (
      <>
        {remaining.map(({ node }) => formatEntry(node))}
      </>
    )
  }
}

function isHiring(entries) {
  // find relevant entries
  let remaining = entries.filter(({ node }) => {
    const { title, tags } = node.frontmatter
    
    return ((title.length > 0) && tags.includes("hiring") && !tags.includes("expired") && tags.includes("upcoming-positions"))
  })
  // conditional rendering
  if (remaining.length == 0) {
    return (
      <div><p><i>No upcoming positions at this time.  Please check back regularly!</i></p></div>
    )
  } else {
  return (
    <>
      {remaining.map(({ node }) => formatEntry(node))}
    </>
  )
}
}

function pastHiring(entries) {
  // find relevant entries
  let remaining = entries.filter(({ node }) => {
    const { title, tags } = node.frontmatter
    
    return ((title.length > 0) && tags.includes("hiring") && tags.includes("expired") && !tags.includes("upcoming-positions"))
  })
  // conditional rendering
  if (remaining.length == 0) {
    return (
      <div><p>No positions found (???)</p></div>
    )
  } else {
    return (
      <>
        {remaining.map(({ node }) => formatEntry(node))}
      </>
    )
  }
}

function JobAds() {
  return (
    <>
      <p>Looking for paid opportunities to work on research projects? <Link to={`/jobs`}>See this page for active and past job posts</Link>.</p>
    </>
  )
}

function RawHiringPage() {
  const { edges: recent } = useMdxEntries()
  return (
    <>
      <h3>Actively hiring</h3>
      {activelyHiring(recent)}
      <h3>Upcoming positions</h3>
      {isHiring(recent)}
      <h3>Past positions</h3>
      {pastHiring(recent)}
    </>
  )
}

function HiringPage() {
  return (
    <BasicLayout>
      <RawHiringPage/>
    </BasicLayout>
  )
}

export {
  isHiring,
  activelyHiring,
  pastHiring,
  RawHiringPage,
  HiringPage,
  JobAds
}
