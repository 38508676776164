import { useStaticQuery, graphql } from "gatsby"

export const useMdxEntries = () => {
  const { allMdx } = useStaticQuery(
    graphql`
    query recentPages {
      allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {section: {eq: "news"}}}) {
        edges {
          node {
            id
            #excerpt(pruneLength: 200)
            fields { slug }
            frontmatter {
              date(formatString: "MMMM YYYY")
              #date(formatString: "MM/DD/YYYY")
              year: date(formatString: "YYYY")
              title
              section
              tags
            }
          }
        }
      }
    }
    `
  )
  return allMdx
}