import React from 'react'
import { graphql, Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'
import { RawHiringPage, JobAds } from 'src/components/jobs'
import { useMdxEntries } from 'src/hooks/use-mdx-entries'
import { RawNewsPage } from '../../components/news'
import { formatEntry } from 'src/components/utils'

function IndexPage() {
  const { edges: recent } = useMdxEntries()
  return (
    <BasicLayout>
      <h1>News</h1>
      {<RawNewsPage maxYears={-1}/>}
      <h1>Hiring</h1>
      {<JobAds/>}
    </BasicLayout>
  )
}


export default IndexPage