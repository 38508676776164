import React from 'react'
import { Link } from 'gatsby'

/**
 * Takes an MDX node and returns a formatted entry
 */
function formatEntry(node) {
  const { date, title, year, tags } = node.frontmatter
  if (tags.includes("expired")) {
    return (
      <div key={node.id}>
        <h4><span>{date}</span>: <strike><Link to={`${node.fields.slug}`}>{title}</Link></strike></h4>
        <hr />
      </div>
    )
  } else {
    return (
      <div key={node.id}>
        <h4><span>{date}</span>: <Link to={`${node.fields.slug}`}>{title}</Link></h4>
        {/* <p><i>{node.excerpt}</i></p> */}
        <hr />
      </div>
    )
  }
}

export {
  formatEntry
}